import {
  Box,
  Button,
  Group,
  Text,
  TextInput,
  Autocomplete,
  NumberInput,
  Grid,
  Container,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import DropZone from "./common/DropZone/DropZone";
import {
  acceptableCardFileExt,
  acceptablePdfFileExt,
} from "../constant/constant";

export default function AddEditDatasetForm(props: any) {
  const {
    onSubmit,
    initialValues,
    actionLoading,
    actionType,
    packageTypes,
    componentTypes,
  } = props;
  const [draFile, setDraFile] = useState<any>();
  const [stpFile, setStpFile] = useState<any>();
  const [stepFile, setStepFile] = useState<any>();
  const [dxfFile, setDxfFile] = useState<any>();
  const [dwgFile, setDwgFile] = useState<any>();
  const [datasetFile, setDatasetFile] = useState<any>();
  const [updatablePackageTypes, setUpdatablePackageTypes] = useState<any>([]);
  const [updatableComponentTypes, setUpdatableComponentTypes] = useState<any>(
    []
  );
  const [removedFiles, setRemovedFiles] = useState<any>({});

  useEffect(() => {
    const types = packageTypes?.map((type: any) => type.value);
    setUpdatablePackageTypes(types);
  }, [packageTypes]);

  useEffect(() => {
    const types = componentTypes?.map((type: any) => type.value);
    setUpdatableComponentTypes(types);
  }, [componentTypes]);

  const form = useForm({
    initialValues: initialValues || {
      id: "",
      serial_no: "",
      manufacture_name: "",
      package_type: "",
      component_type: "",
      nominal_dimensions: "",
    },

    validate: {
      serial_no: (value) => (!value ? "Please enter part number" : null),
      /* manufacture_name: (value) =>
        !value ? "Please enter manufacture name" : null,
      package_type: (value) => (!value ? "Please enter package type" : null), */
    },
  });

  const handleSubmit = (values: any) => {
    onSubmit({
      values,
      files: {
        dra: draFile,
        stp: stpFile,
        step: stepFile,
        dxf: dxfFile,
        dwg: dwgFile,
        dataset: datasetFile,
      },
      removedFiles,
    });
  };

  useEffect(() => {
    const isPackageTypeExist = packageTypes?.find((packageType: any) =>
      packageType.value
        .toLowerCase()
        .includes(form?.values?.package_type.toLowerCase().trim())
    );
    if (!isPackageTypeExist && form?.values?.component_type) {
      setUpdatablePackageTypes([form?.values?.package_type]);
    } else {
      setUpdatablePackageTypes(packageTypes);
    }
  }, [packageTypes, form?.values]);

  useEffect(() => {
    const isComponentTypeExist = componentTypes.find((componentType: any) =>
      componentType.value
        .toLowerCase()
        .includes(form?.values?.component_type.toLowerCase().trim())
    );
    if (!isComponentTypeExist && form?.values?.component_type) {
      setUpdatableComponentTypes([form?.values?.component_type]);
    } else {
      setUpdatableComponentTypes(componentTypes);
    }
  }, [componentTypes, form?.values]);

  const clearFile = (field: string, clearedFile: string) => {
    setRemovedFiles({ ...removedFiles, [field]: clearedFile });
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Flex wrap={"wrap"} justify={"center"}>
          <Container>
            <TextInput
              withAsterisk
              disabled={actionType === "editCard" ? true : actionLoading}
              label={
                <Text weight={"bold"} sx={{ display: "inline-block" }}>
                  Manufacturer Part Number
                </Text>
              }
              placeholder="Manufacturer Part Number"
              {...form.getInputProps("serial_no")}
            />
          </Container>
          <Container>
            <TextInput
              // withAsterisk
              disabled={actionLoading}
              label={
                <Text weight={"bold"} sx={{ display: "inline-block" }}>
                  Manufacturer Name
                </Text>
              }
              placeholder="Manufacturer name"
              {...form.getInputProps("manufacture_name")}
            />{" "}
          </Container>
          <Container>
            <Autocomplete
              // withAsterisk
              styles={{
                label: { display: "inline-block", fontWeight: "bold" },
              }}
              disabled={actionLoading}
              {...form.getInputProps("package_type")}
              label="Mounting Style"
              data={updatablePackageTypes}
              placeholder="Mounting Style"
              sx={{ display: "inline-block" }}
            />{" "}
          </Container>
          <Group>
            <Autocomplete
              styles={{
                label: { display: "inline-block", fontWeight: "bold" },
                root: { width: "100%" },
              }}
              mt={"xl"}
              disabled={actionLoading}
              {...form.getInputProps("component_type")}
              label="Component type"
              data={updatableComponentTypes}
              placeholder="Component type"
            />{" "}
            <TextInput
              // withAsterisk
              disabled={actionLoading}
              styles={{
                label: { display: "inline-block", fontWeight: "bold" },
                root: { width: "100%" },
              }}
              label={
                <Text sx={{ display: "inline-block" }}>Nominal Dimensions</Text>
              }
              mb={50}
              placeholder="Nominal Dimensions"
              {...form.getInputProps("nominal_dimensions")}
            />{" "}
          </Group>
          <Group>
            <Container>
              <Text weight={"bold"} sx={{ display: "inline-block" }}>
                Footprint
              </Text>
              <DropZone
                name="dra_file"
                setFile={setDraFile}
                clear={clearFile}
                uploadedFileName={initialValues?.uploadedDraFileName}
                uploadedFileUrl={initialValues?.uploadedDraFileUrl}
                acceptFile={["dra"]}
                isSingleFile={true}
              />
            </Container>
            <Container>
              <Text weight={"bold"} sx={{ display: "inline-block" }}>
                STP File
              </Text>
              <DropZone
                name="stp_file"
                setFile={setStpFile}
                clear={clearFile}
                uploadedFileName={initialValues?.uploadedStpFileName}
                uploadedFileUrl={initialValues?.uploadedStpFileUrl}
                acceptFile={["stp"]}
                isSingleFile={true}
              />{" "}
            </Container>
            <Container>
              <Text weight={"bold"} sx={{ display: "inline-block" }}>
                STEP File
              </Text>

              <DropZone
                name="step_file"
                setFile={setStepFile}
                clear={clearFile}
                uploadedFileName={initialValues?.uploadedStepFileName}
                uploadedFileUrl={initialValues?.uploadedStepFileUrl}
                acceptFile={["step"]}
                isSingleFile={true}
              />
            </Container>
          </Group>{" "}
          <Group>
            <Container>
              <Text weight={"bold"} sx={{ display: "inline-block" }}>
                DXF File
              </Text>

              <DropZone
                name="dxf_file"
                setFile={setDxfFile}
                clear={clearFile}
                uploadedFileName={initialValues?.uploadedDxfFileName}
                uploadedFileUrl={initialValues?.uploadedDxfFileUrl}
                acceptFile={["dxf"]}
                isSingleFile={true}
              />
            </Container>
            <Container>
              <Text weight={"bold"} sx={{ display: "inline-block" }}>
                DWG File
              </Text>
              <DropZone
                name="dwg_file"
                setFile={setDwgFile}
                clear={clearFile}
                uploadedFileName={initialValues?.uploadedDwgFileName}
                uploadedFileUrl={initialValues?.uploadedDwgFileUrl}
                acceptFile={["dwg"]}
                isSingleFile={true}
              />{" "}
            </Container>
            <Container>
              <Text weight={"bold"} sx={{ display: "inline-block" }}>
                Datasheet File
              </Text>
              <DropZone
                name="dataset_file"
                setFile={setDatasetFile}
                clear={clearFile}
                isSingleFile={true}
                uploadedFileName={initialValues?.uploadedDatasetFileName}
                uploadedFileUrl={initialValues?.uploadedDatasetFileUrl}
                acceptFile={acceptablePdfFileExt}
              />{" "}
            </Container>{" "}
          </Group>
        </Flex>
        <Group position="right" mt="md">
          <Button type="submit" loading={actionLoading}>
            Submit
          </Button>
        </Group>
      </form>
    </Box>
  );
}
