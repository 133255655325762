import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  accessToken: string;
  refreshToken: string;
  userPermissions: Array<string>;
  userRole: string;
  phoneNoConfirmationResult: any;
  errorStatusCode: string | null;
}

const initialState: AuthState = {
  accessToken: "",
  refreshToken: "",
  userPermissions: [],
  userRole: "",
  phoneNoConfirmationResult: null,
  errorStatusCode: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setPhoneNoConfirmationResult: (state, action: PayloadAction<any>) => {
      state.phoneNoConfirmationResult = action.payload;
    },
    setUserRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },
    setUserPermission: (state, action: PayloadAction<any>) => {
      state.userPermissions = action.payload;
    },
    setErrorStatusCode: (state, action: PayloadAction<any>) => {
      state.errorStatusCode = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  setPhoneNoConfirmationResult,
  setUserRole,
  setUserPermission,
  setErrorStatusCode,
} = authSlice.actions;

export default authSlice.reducer;
