import { useMantineTheme } from "@mantine/core";
import "./Button.css";

function Button(props: any) {
  const theme = useMantineTheme();
  return (
    <button
      className={`primary-btn ${!props.notFullWidth ? "w-100" : ""}`}
      {...props}
      onClick={props.handleClick}
      style={{
        backgroundColor:
          props.color === "secondary"
            ? theme.colors["maaglim-red"][9]
            : theme.fn.primaryColor(),
      }}
    >
      {props.text}
    </button>
  );
}
export default Button;
