import "./VerifyPhoneNo.css";
import { auth } from "../../firebase";
import Button from "../common/Button/Button";
import httpService from "../../services/http.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../common/Input/Input";
import { useEffect, useState } from "react";
import Toast from "../common/Toast/Toast";
import { setUser } from "../../reducers/userReducer";
import { setAccessToken, setErrorStatusCode, setRefreshToken } from "../../reducers/authReducer";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { LoadingOverlay } from "@mantine/core";

function VerifyPhoneNo() {
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state: any) => state.user.userData);
  const phoneNoResult = useSelector(
    (state: any) => state.auth.phoneNoConfirmationResult
  );
  const [confirmationResult, setConfirmationResult] = useState(phoneNoResult);

  const getVerificationCode = async () => {
    const captchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    try {
      setIsLoading(true);
      captchaVerifier.render();

      const response = await signInWithPhoneNumber(
        auth,
        userData.phone_no,
        captchaVerifier
      );
      setConfirmationResult(() => {
        return response;
      });
      const data = {
        ...userData,
        is2FAviaEmailAvailable: false,
      };
      dispatch(setUser(data));
      captchaVerifier.clear();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message || error?.message);
      dispatch(setErrorStatusCode(error?.response?.status))
    }
  };

  useEffect(() => {
    if (!confirmationResult.onConfirmation) {
      getVerificationCode();
    }
  }, []);

  const verify = async () => {
    try {
      setIsLoading(true);
      if (confirmationResult) {
        await confirmationResult.confirm(code);
        const idToken = await auth?.currentUser?.getIdToken(true);
        let verifyPhoneNoResponse;

        if (userData.is2FAviaPhoneNoAvailable) {
          verifyPhoneNoResponse = await httpService.post(
            "auth/2fa/phone-no",
            { idToken },
            {}
          );
        } else {
          verifyPhoneNoResponse = await httpService.post(
            "auth/verify-phone-no",
            { idToken },
            {}
          );
        }
        dispatch(setAccessToken(verifyPhoneNoResponse.data.accessToken));
        dispatch(setRefreshToken(verifyPhoneNoResponse.data.refreshToken));
      }
      const data = {
        ...userData,
        is_phone_verified: true,
        ...(userData.is2FAviaPhoneNoAvailable && {
          is2FAviaPhoneNoAvailable: false,
        }),
      };
      dispatch(setUser(data));
      navigate("/");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log("ERROR: ", error);
      setError(error?.response?.data?.message || error?.message);
      dispatch(setErrorStatusCode(error?.response?.status))
    }
  };

  return (
    <div className="verify-container">
      <div id="recaptcha-container"></div>
      {error && <Toast message={error} />}
      <LoadingOverlay visible={isLoading} loaderProps={{ variant: "oval" }} />
      {!isLoading && (
        <div className="child-container w-100 m-2">
          <div className="text-center mb-6">
            <img src="../../logo/maaglim.webp"></img>
          </div>
          <div className="my-1 text-center">Enter the verification code</div>
          <div className="mt-4">
            <Input
              type="text"
              placeholder="Enter verification code"
              value={code}
              onInputChange={(e: any) => setCode(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <Button text="Confirm" handleClick={verify} />
          </div>
          <div className="d-flex justify-flex-end">
            <button onClick={getVerificationCode} className="resend-btn">
              Resend verification code
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VerifyPhoneNo;
