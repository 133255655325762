import "./VerifyEmail.css";
import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Button from "../common/Button/Button";
import httpService from "../../services/http.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../common/Toast/Toast";
import ConfirmationDialog from "../common/ConfirmationDialog/ConfirmationDialog";
import { setUser } from "../../reducers/userReducer";
import {
  setAccessToken,
  setErrorStatusCode,
  setRefreshToken,
  setUserPermission,
  setUserRole,
} from "../../reducers/authReducer";
import { sendEmailVerification } from "firebase/auth";
import { LoadingOverlay } from "@mantine/core";

function VerifyEmail() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifiedEmailResponse, setVerifiedEmailResponse]: any = useState({});
  const navigate = useNavigate();
  const userData = useSelector((state: any) => state.user.userData);
  const firebaseUser = useSelector((state: any) => state.user.firebaseUser);

  const resendVerificationEmail = async () => {
    try {
      setIsLoading(true);
      const idToken = await auth?.currentUser?.getIdToken(true);
      await httpService.post("auth/reset-email-verification", { idToken }, {});
      if (firebaseUser) {
        await sendEmailVerification(firebaseUser).then(() => {});
        const data = {
          ...userData,
          is2FAviaPhoneNoAvailable: false,
        };
        dispatch(setUser(data));
      }
      setSuccess("Email sent successfully");
      setIsLoading(false);
    } catch (error: any) {
      console.log("ERROR: ", error);
      setIsLoading(false);
      setError(error?.response?.data?.message || error?.message);
      dispatch(setErrorStatusCode(error?.response?.status));
    }
  };

  const verify = async () => {
    try {
      setIsLoading(true);
      const idToken = await auth?.currentUser?.getIdToken(true);
      let verifyEmailResponse: any;
      if (userData.is2FAviaEmailAvailable) {
        verifyEmailResponse = await httpService.post(
          "auth/2fa/email",
          { idToken },
          {}
        );
      } else {
        verifyEmailResponse = await httpService.post(
          "auth/verify-email",
          { idToken },
          {}
        );
      }

      setVerifiedEmailResponse(verifyEmailResponse)
      if (userData?.phone_no && !userData.is2FAviaEmailAvailable) {
        setShowDialog(true);
      } else {
        dispatch(setUserRole(verifyEmailResponse?.data?.role));
        dispatch(setAccessToken(verifyEmailResponse?.data?.accessToken));
        dispatch(setRefreshToken(verifyEmailResponse?.data?.refreshToken));
        dispatch(setUserPermission(verifyEmailResponse?.data?.permissions));
        onCancelPhoneVerification();
      }
      
      setIsLoading(false);
    } catch (error: any) {
      console.log("ERROR: ", error);
      setIsLoading(false);
      setError(error?.response?.data?.message || error?.message);
      dispatch(setErrorStatusCode(error?.response?.status));
    }
  };

  useEffect(() => {
    if (showDialog) {
      dispatch(setUserRole(verifiedEmailResponse?.data?.role));
      dispatch(setAccessToken(verifiedEmailResponse?.data?.accessToken));
      dispatch(setRefreshToken(verifiedEmailResponse?.data?.refreshToken));
      dispatch(setUserPermission(verifiedEmailResponse?.data?.permissions));
    }
  }, [showDialog])

  const updateUserData = () => {
    const data = {
      ...userData,
      is_email_verified: true,
      ...(userData.is2FAviaEmailAvailable && {
        is2FAviaEmailAvailable: false,
      }),
    };
    dispatch(setUser(data));
  };

  const onConfirmPhoneVerification = () => {
    setShowDialog(false);
    updateUserData();
    navigate("/verify-phone-no");
  };

  const onCancelPhoneVerification = () => {
    setShowDialog(false);
    updateUserData();
    navigate("/");
  };

  return (
    <div className="verify-container">
      {error && <Toast message={error} />}
      {success && <Toast message={success} success={true} />}
      {showDialog && (
        <ConfirmationDialog
          text="Do you want to verify the phone number ?"
          confirmText="Yes"
          cancelText="No"
          onConfirm={onConfirmPhoneVerification}
          OnCancel={onCancelPhoneVerification}
        />
      )}
      <LoadingOverlay visible={isLoading} loaderProps={{ variant: "oval" }} />
      {!isLoading && (
        <div className="child-container w-100 m-2">
          <div className="text-center mb-6">
            <img src="../../logo/maaglim.webp"></img>
          </div>
          <div className="my-1 text-center">
            Verification email sent to
            <span className="font-bold"> {userData?.email_id} </span> click here
            if email verified successfully
          </div>
          <div className="mt-4">
            <Button text="Confirm" handleClick={verify} />
          </div>
          <div className="d-flex justify-flex-end">
            <button onClick={resendVerificationEmail} className="resend-btn">
              Resend verification email
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;
