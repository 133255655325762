import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./SignUp.css";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import Input from "../common/Input/Input";
import { auth } from "../../firebase";
import Button from "../common/Button/Button";
import httpService from "../../services/http.service";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../common/Toast/Toast";
import { setUser } from "../../reducers/userReducer";
import { setErrorStatusCode, setPhoneNoConfirmationResult } from "../../reducers/authReducer";
import { messageConstants } from "../../constant/message.constant";
import { LoadingOverlay, useMantineTheme } from "@mantine/core";

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = () => {
    let isValid = true;
    if (!password) {
      isValid = false;
      setError(messageConstants.PASSWORD_MISSING);
    }
    if (!confirmPassword) {
      isValid = false;
      setError(messageConstants.CONFIRM_PASSWORD_MISSING);
    }
    if (password !== confirmPassword) {
      isValid = false;
      setError(messageConstants.PASSWORD_NOT_MATCH);
    }
    return isValid;
  };

  const register = async () => {
    if (!email) {
      setError(messageConstants.EMAIL_MISSING);
      return;
    }
    if (validatePassword()) {
      try {
        setIsLoading(true);
        // Create a new user with email and password using firebase
        const res: any = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        if (phoneNo) {
          const captchaVerifier = new RecaptchaVerifier(
            "sign-in-button-captcha",
            {
              size: "invisible",
            },
            auth
          );
          captchaVerifier.render();
          const response = await signInWithPhoneNumber(
            auth,
            phoneNo,
            captchaVerifier
          );
          dispatch(setPhoneNoConfirmationResult(response));
        }
        await sendEmailVerification(res.user);
        const idToken = await auth?.currentUser?.getIdToken(true);
        const params: any = { idToken };

        if (phoneNo) {
          params.phoneNo = phoneNo;
        }
        const userData = await httpService.post("auth/signup", params, {});
        setError("");
        dispatch(setUser(userData.data));
        navigate("/verify-email");
      } catch (error: any) {
        setIsLoading(false);
        if (auth?.currentUser) {
          await auth?.currentUser.delete();
        }
        console.log("ERROR: ", error);
        setError(error?.response?.data?.message || error?.message);
      }
    }
  };

  useEffect(() => {
    dispatch(setErrorStatusCode(null))
  }, [])

  return (
    <div className="signup-container">
      <div id="sign-in-button-captcha"></div>
      {error && <Toast message={error} />}
      <LoadingOverlay visible={isLoading} loaderProps={{ variant: "oval" }} />
      {!isLoading && (
        <div className="child-container w-100 m-2">
          <div className="text-center mb-6">
            <img src="../../logo/maaglim.webp"></img>
          </div>
          <div className="input-group">
            <div className="my-1">Email</div>
            <Input
              type="text"
              placeholder="Enter email"
              value={email}
              onInputChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <div className="my-1">Phone number</div>
            <Input
              type="text"
              placeholder="Enter phone no"
              value={phoneNo}
              onInputChange={(e: any) => setPhoneNo(e.target.value)}
            />
          </div>
          <div className="input-group">
            <div className="my-1">Password</div>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onInputChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <div className="my-1">Confirm password</div>
            <Input
              type="password"
              placeholder="Enter confirm password"
              value={confirmPassword}
              onInputChange={(e: any) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="signup-btn">
            <Button text="Sign up" handleClick={register} />
          </div>
          <div className="mt-4 text-center">
            <span className="color-light-grey">Already sign up?</span>{" "}
            <Link style={{ color: theme.fn.primaryColor() }} to="/login">
              Login
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUp;
