import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Users from "../Users/Users";
import SignUp from "../SignUp/SignUp";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import VerifyPhoneNo from "../VerifyPhoneNo/VerifyPhoneNo";
import ChooseTwoFaOption from "../ChooseTwoFaOption/ChooseTwoFaOption";
import TrashCard from "../TrashCard/TrashCard";
import { Roles } from "../../constant/constant";

function Routing() {
  const userData = useSelector((state: any) => state.user.userData);
  const { userRole } = useSelector((state: any) => state.auth);

  const PrivateRoute = (data: any) => {
    if (!userData) {
      return <Navigate to="/login" />;
    } else if (
      !userData?.is_email_verified ||
      (userData.is2FAviaEmailAvailable && !userData.is2FAviaPhoneNoAvailable)
    ) {
      return <Navigate to="/verify-email" />;
    } else if (
      !userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-phone-no" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/choose-2fa-type" />;
    } else {
      return data.children;
    }
  };

  const VerifyEmailRoute = (data: any) => {
    if (!userData) {
      return <Navigate to="/login" />;
    } else if (
      userData?.is_email_verified &&
      !userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/" />;
    } else if (
      !userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-phone-no" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/choose-2fa-type" />;
    } else {
      return data.children;
    }
  };

  const VerifyPhoneNoRoute = (data: any) => {
    if (!userData) {
      return <Navigate to="/login" />;
    } else if (
      userData?.is_email_verified &&
      userData.is2FAviaEmailAvailable !== undefined &&
      userData.is2FAviaPhoneNoAvailable !== undefined &&
      !userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-email" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/choose-2fa-type" />;
    } else {
      return data.children;
    }
  };

  const AuthRoute = (data: any) => {
    if (!userData) {
      return data.children;
    } else if (
      userData?.is_email_verified &&
      !userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-email" />;
    } else if (
      !userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-phone-no" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/choose-2fa-type" />;
    }
  };

  const Choose2FaRoute = (data: any) => {
    if (!userData) {
      return <Navigate to="/login" />;
    } else if (
      userData?.is_email_verified &&
      !userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      !userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-email" />;
    } else if (
      !userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return <Navigate to="/verify-phone-no" />;
    } else if (
      userData.is2FAviaEmailAvailable &&
      userData.is2FAviaPhoneNoAvailable
    ) {
      return data.children;
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/trash-cards"
        element={
          userRole !== Roles.VIEWER ? (
            <PrivateRoute>
              <TrashCard />
            </PrivateRoute>
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/users"
        element={
          userRole === Roles.ADMIN ? (
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/signup"
        element={
          <AuthRoute>
            <SignUp />
          </AuthRoute>
        }
      />
      <Route
        path="/login"
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route
        path="/verify-email"
        element={
          <VerifyEmailRoute>
            <VerifyEmail />
          </VerifyEmailRoute>
        }
      />
      <Route
        path="/verify-phone-no"
        element={
          <VerifyPhoneNoRoute>
            <VerifyPhoneNo />
          </VerifyPhoneNoRoute>
        }
      />
      <Route
        path="/choose-2fa-type"
        element={
          <Choose2FaRoute>
            <ChooseTwoFaOption />
          </Choose2FaRoute>
        }
      />
    </Routes>
  );
}

export default Routing;
