import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  ActionIcon,
  Box,
  Checkbox,
  Flex,
  LoadingOverlay,
  MediaQuery,
  Pagination,
  Paper,
  Select,
  Table,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconFilterOff, IconTrash, IconTrashOff } from "@tabler/icons-react";
import dayjs from "dayjs";
import CustomModal from "../common/modal";
import { DateRangePicker } from "@mantine/dates";
import httpService from "../../services/http.service";
import { MultiSelect } from "react-multi-select-component";
import { TableRecordsLimit } from "../../constant/constant";
import {
  extractImgName,
  getFormattedDate,
  getOriginalFileName,
} from "../utils";
import { useDebouncedState, useDebouncedValue } from "@mantine/hooks";
import Toast from "../common/Toast/Toast";
import { setErrorStatusCode, setUserRole } from "../../reducers/authReducer";

function TrashCard() {
  const dispatch = useDispatch();

  const accessToken = useSelector((state: any) => state.auth.accessToken);
  const permissions = useSelector((state: any) => state.auth.userPermissions);
  const [userPermissions, setUserPermissions] = useState(permissions);

  const [data, setData] = useState<any[]>([]);
  const [meta, setMeta] = useState<Record<string, string>>({});
  const [activePage, setPage] = useState(1);
  const [recordsLimit, setRecordsLimit] = useState<string>(TableRecordsLimit);
  const [cardsToDelete, setCardsToDelete] = useState<string[]>([]);

  const [error, setError] = useState("");
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>();
  const [selectedCardIds, setSelectedCardIds] = useState<string[]>();

  const [isLoading, setIsLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionType, setActionType] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const [filterFields, setFilterFields] = useState<any>({});
  const emptySelectedFilters = {
    serialNumbers: [],
    manufactureNames: [],
    packageTypes: [],
    componentTypes: [],
    createdAt: [null, null],
  };
  const [selectedFilters, setSelectedFilters] = useDebouncedState<any>(
    emptySelectedFilters,
    300
  );

  const getQueryStringValues = (key: string) => {
    return selectedFilters[key].map((obj: any) => obj.value).join(",");
  };

  const getPermissions = () => {
    if (accessToken) {
      httpService
        .get("permission/get-user-permissions", {
          Authorization: accessToken,
        })
        .then((res) => {
          setIsLoading(false);
          dispatch(setUserRole(res.data.role));
          setUserPermissions(res.data.permissions);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
    }
  };

  const fetchData = useCallback(() => {
    if (accessToken) {
      let queryParams = `card?limit=${recordsLimit}&page=${activePage}&isDeleted=true`;
      if (debouncedSearchQuery)
        queryParams += `&manufactureName=${debouncedSearchQuery}&packageType=${debouncedSearchQuery}`;
      if (selectedFilters.serialNumbers.length) {
        queryParams += `&serialNumbers=${getQueryStringValues(
          "serialNumbers"
        )}`;
      }
      if (selectedFilters.manufactureNames.length) {
        queryParams += `&manufactureNames=${getQueryStringValues(
          "manufactureNames"
        )}`;
      }
      if (selectedFilters.packageTypes.length) {
        queryParams += `&packageTypes=${getQueryStringValues("packageTypes")}`;
      }
      if (selectedFilters.componentTypes.length) {
        queryParams += `&componentTypes=${getQueryStringValues(
          "componentTypes"
        )}`;
      }
      if (selectedFilters.createdAt.length) {
        const [start, end] = selectedFilters.createdAt;
        if (start && end) {
          const formattedStart = dayjs(start).format("YYYY-MM-DD");
          const formattedEnd = dayjs(end).format("YYYY-MM-DD");
          queryParams += `&createdAt=${formattedStart},${formattedEnd}`;
        }
      }
      httpService
        .get(queryParams, {
          Authorization: accessToken,
        })
        .then((res) => {
          setIsLoading(false);
          const cards = res.data.data.map((card: any) => ({
            ...card,
            file: card.file ? getOriginalFileName(card.file) : "",
            checked: false,
          }));
          setData(cards);
          setMeta(res.data.meta);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
      fetchFilterFields();
    } else {
      setIsLoading(false);
    }
  }, [recordsLimit, activePage, debouncedSearchQuery, selectedFilters]);

  const fetchFilterFields = useCallback(() => {
    if (!data) return;
    httpService
      .get("card/filter-fields?isDeleted=true", { Authorization: accessToken })
      .then((response) => {
        Object.keys(response.data).map((key) => {
          response.data[key] = response.data[key].map((f: any) => ({
            label: f[key],
            value: f[key],
          }));
        });
        setFilterFields(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getPermissions();
  }, []);

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedCardId(undefined);
      setActionType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleDelete = (cardId: string) => {
    setSelectedCardId(cardId);
    setActionType("deleteCard");
    toggleModal();
  };

  const handleRestore = (cardIds: string[]) => {
    setSelectedCardIds(cardIds);
    setActionType("restoreCard");
    toggleModal();
  };

  const deleteCard = () => {
    setActionLoading(true);
    httpService
      .deleteRequest(
        `card/${selectedCardId}/true`,
        {},
        { Authorization: accessToken }
      )
      .then(() => {
        if (activePage === 1) {
          fetchData();
        } else {
          setPage(1);
        }
        toggleModal();
        setActionLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  };

  const restoreCards = () => {
    setActionLoading(true);
    httpService
      .put(
        `card/restore`,
        {
          ids: selectedCardIds,
        },
        { Authorization: accessToken }
      )
      .then(() => {
        if (activePage === 1) {
          fetchData();
        } else {
          setPage(1);
        }
        toggleModal();
        setActionLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  };

  const deleteBulkCard = () => {
    setActionLoading(true);
    httpService
      .deleteRequest(
        "card/bulk",
        {
          ids: cardsToDelete,
          isDeleteFromTrash: true,
        },
        {
          Authorization: accessToken,
        }
      )
      .then(() => {
        if (activePage === 1) {
          fetchData();
        } else {
          setPage(1);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
    toggleModal();
    setActionLoading(false);
  };

  const handleDeleteBulkCard = () => {
    const cards = data.filter((c) => c.checked).map((c) => c.id);
    if (!cards.length) return;
    setCardsToDelete(cards);
    setActionType("deleteBulkCard");
    toggleModal();
  };

  const handleRestoreBulkCard = () => {
    const cards = data.filter((c) => c.checked).map((c) => c.id);
    if (!cards.length) return;
    setSelectedCardIds(cards);
    setActionType("restoreBulkCard");
    toggleModal();
  };

  const toggleCardCheck = (checked: boolean, cardId: string) => {
    const updatedCards = data.map((c) => {
      if (c.id === cardId) c.checked = checked;
      return c;
    });
    setData(updatedCards);
  };

  const rows = data.map((element) => (
    <tr key={element.id}>
      {userPermissions?.includes("card.delete") && (
        <td>
          <Checkbox
            checked={element.checked}
            onChange={(e) => toggleCardCheck(e.target.checked, element.id)}
            size="xs"
          />
        </td>
      )}
      <td>{element.serial_no}</td>
      <td>{element.manufacture_name}</td>
      <td>{element.package_type}</td>
      <td>{element.component_type || "-"}</td>
      <td>{getFormattedDate(element.created_at)}</td>
      <td>
        <div className="d-flex">
          {userPermissions?.includes("card.restore") && (
            <div className="mx-1">
              <ActionIcon
                size="sm"
                color="maaglim-blue"
                variant="subtle"
                onClick={() => handleRestore([element.id])}
              >
                <IconTrashOff />
              </ActionIcon>
            </div>
          )}
          {userPermissions?.includes("card.delete") && (
            <div className="mx-1">
              <ActionIcon
                size="sm"
                color="maaglim-red.9"
                variant="subtle"
                onClick={() => handleDelete(element.id)}
              >
                <IconTrash></IconTrash>
              </ActionIcon>
            </div>
          )}
        </div>
      </td>
    </tr>
  ));

  const handleRecordsLimitChange = (limit: string) => {
    setRecordsLimit(limit);
    setPage(1);
  };

  const handleSelectedFiltersChange = (key: string, value: any) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
  };

  const renderFilters = () => {
    return (
      <div className="d-flex align-items-center gap-1 filter-fields">
        {error && <Toast message={error} />}
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Serial Number</label>
          <MultiSelect
            options={filterFields?.serial_no || []}
            value={selectedFilters?.serialNumbers || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("serialNumbers", list)
            }
            labelledBy="Filter by Serial Number"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Manufacturer</label>
          <MultiSelect
            options={filterFields?.manufacture_name || []}
            value={selectedFilters?.manufactureNames || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("manufactureNames", list)
            }
            labelledBy="Filter by Manufacturer"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Package Type</label>
          <MultiSelect
            options={filterFields?.package_type || []}
            value={selectedFilters?.packageTypes || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("packageTypes", list)
            }
            labelledBy="Filter by Package Type"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Component Type</label>
          <MultiSelect
            options={filterFields.component_type || []}
            value={selectedFilters?.componentTypes || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("componentTypes", list)
            }
            labelledBy="Filter by Component Type"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Created Date</label>
          <DateRangePicker
            placeholder="Pick dates"
            onChange={(dates) =>
              handleSelectedFiltersChange("createdAt", dates)
            }
            withinPortal
          />
        </div>
        <Tooltip label="Reset Filters" withinPortal>
          <ActionIcon
            color="maaglim-blue"
            variant="filled"
            onClick={() => setSelectedFilters(emptySelectedFilters)}
          >
            <IconFilterOff />
          </ActionIcon>
        </Tooltip>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Box p={"md"}>
          {actionType === "deleteBulkCard" && (
            <CustomModal
              open={isModalOpen}
              title=""
              closeModal={toggleModal}
              confirmationString="The cards will be deleted permanently and can’t be restored"
              isOk={deleteBulkCard}
              loading={actionLoading}
              children={false}
            />
          )}
          {actionType === "deleteCard" && (
            <CustomModal
              open={isModalOpen}
              title=""
              closeModal={toggleModal}
              confirmationString="The card will be deleted permanently and can’t be restored"
              isOk={deleteCard}
              loading={actionLoading}
              children={false}
            />
          )}
          {actionType === "restoreCard" && (
            <CustomModal
              open={isModalOpen}
              title=""
              closeModal={toggleModal}
              confirmationString="Are you sure want to restore this card ?"
              isOk={restoreCards}
              loading={actionLoading}
              children={false}
            />
          )}
          {actionType === "restoreBulkCard" && (
            <CustomModal
              open={isModalOpen}
              title=""
              closeModal={toggleModal}
              confirmationString="Are you sure want to restore these cards ?"
              isOk={restoreCards}
              loading={actionLoading}
              children={false}
            />
          )}
          <Paper shadow="xs" p="sm" withBorder mb={"md"}>
            <MediaQuery
              query="(max-width: 575px)"
              styles={{
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <div className="d-flex justify-space-between">
                <div>
                  <Title order={3}>DRA Card Management</Title>
                </div>
                <div className="d-flex justify-space-between gap-1">
                  {/* commented for the time being */}
                  {/* <TextInput
                    placeholder="Search"
                    className="w-100"
                    value={searchQuery}
                    onChange={(event) =>
                      setSearchQuery(event.currentTarget.value)
                    }
                  /> */}
                  {userPermissions?.includes("card.delete") && (
                    <Tooltip label="Delete Cards">
                      <ActionIcon
                        size="lg"
                        color="maaglim-red.9"
                        variant="filled"
                        onClick={() => {
                          handleDeleteBulkCard();
                        }}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  )}
                  {userPermissions?.includes("card.restore") && (
                    <Tooltip label="Restore Cards">
                      <ActionIcon
                        size="lg"
                        color="maaglim-blue.9"
                        variant="filled"
                        onClick={() => {
                          handleRestoreBulkCard();
                        }}
                      >
                        <IconTrashOff />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </div>
              </div>
            </MediaQuery>
          </Paper>
          <MediaQuery query="(max-width: 767px)" styles={{ display: "none" }}>
            <Paper shadow="xs" p="sm" withBorder mb={"md"}>
              {renderFilters()}
            </Paper>
          </MediaQuery>
          <MediaQuery query="(max-width: 767px)" styles={{ display: "block" }}>
            <Paper shadow="xs" withBorder mb={"md"} sx={{ display: "none" }}>
              <Accordion variant="filled">
                <Accordion.Item value="filters">
                  <Accordion.Control>Filters</Accordion.Control>
                  <Accordion.Panel>{renderFilters()}</Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Paper>
          </MediaQuery>
          <Paper
            shadow="xs"
            p="lg"
            withBorder
            mb={"md"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
              marginBottom: "0 !important",
            }}
          >
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ variant: "oval" }}
            />
            {!isLoading && (
              <>
                {rows?.length ? (
                  <>
                    <div className="table-scrollbar">
                      <Table
                        className="custom-table"
                        striped
                        verticalSpacing={"sm"}
                        horizontalSpacing={"sm"}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Serial no</th>
                            <th>Manufacture name</th>
                            <th>Package type</th>
                            <th>Component type</th>
                            <th>Created At</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </Table>
                    </div>
                    <Flex
                      justify="space-between"
                      direction="row"
                      sx={{ marginTop: "0.5rem" }}
                    >
                      <Pagination
                        page={activePage}
                        onChange={(page) => setPage(page)}
                        total={parseInt(meta.totalPages)}
                      />
                      <Select
                        value={recordsLimit}
                        data={[
                          { value: "5", label: "5" },
                          { value: "10", label: "10" },
                          { value: "15", label: "15" },
                          { value: "20", label: "20" },
                        ]}
                        size="xs"
                        sx={{ maxWidth: "75px" }}
                        onChange={(e) => e && handleRecordsLimitChange(e)}
                      />
                    </Flex>
                  </>
                ) : (
                  <div className="text-center font-bold font-size-28">
                    No card found
                  </div>
                )}
              </>
            )}
          </Paper>
        </Box>
      </div>
    </React.Fragment>
  );
}
export default TrashCard;
