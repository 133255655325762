import { Avatar, Popover, Title } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { SignOut } from "../utils";
import Button from "../common/Button/Button";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.user.userData);
  const { userRole } = useSelector((state: any) => state.auth);
  const { pathname } = useLocation();

  return (
    <div
      className={`d-flex ${
        pathname === "/choose-2fa-type"
          ? "justify-flex-end"
          : "justify-space-between"
      } align-items-center nav`}
    >
      {userRole && (
        <img
          className="maaglim-logo"
          src="../../logo/maaglim.webp"
          alt="logo"
        />
      )}
      <Popover
        width={240}
        position="bottom-end"
        withArrow
        withinPortal
        shadow="md"
      >
        <Popover.Target>
          <Avatar radius="xl" className="avatar" />
        </Popover.Target>
        <Popover.Dropdown>
          <div className="d-flex flex-col">
            <span>Signed in as </span>
            <Title order={6}>
              <span className="font-bold">{userData?.email_id}</span>
            </Title>
          </div>
          <div className="my-1">
            <Button
              text="Sign out"
              notFullWidth
              handleClick={() => SignOut(dispatch, navigate)}
            ></Button>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
export default Header;
