import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Routing from "./components/Routing/Routing";
import { HttpStatusCode } from "axios";
import { SignOut } from "./components/utils";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userRole, errorStatusCode, accessToken } = useSelector((state: any) => state.auth);
  const userData = useSelector((state: any) => state.user.userData);

  if (errorStatusCode === HttpStatusCode.Unauthorized || errorStatusCode === HttpStatusCode.Forbidden) {
    SignOut(dispatch, navigate)
  }

  return (
    <div className="d-flex">
      {(userRole && accessToken) && <Sidebar />}
      <main className="mainSection">
        {(accessToken && (userRole || userData)) ? <Header /> : null}
        <Routing />
      </main>
    </div>
  );
}

export default App;
