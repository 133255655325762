import {
  IconCards,
  IconChevronsLeft,
  IconChevronsRight,
  IconMenu2,
  IconTrash,
  IconUsers,
} from "@tabler/icons-react";
import { memo, useCallback, useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import { Roles } from "../../constant/constant";

function Sidebar() {
  const { userRole } = useSelector((state: any) => state.auth);
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = useCallback(() => {
    setCollapsed(!collapsed);
  }, [setCollapsed, collapsed]);

  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <IconMenu2 />
      </div>
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        {/* Header */}
        <SidebarHeader>
          <Menu>
            {collapsed ? (
              <MenuItem
                icon={<IconChevronsRight />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<IconChevronsLeft />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            )}
          </Menu>
        </SidebarHeader>
        {/* Content */}
        <SidebarContent>
          <Menu>
            <MenuItem icon={<IconCards />} active={pathname === "/"}>
              Cards
              <Link to="/" />
            </MenuItem>
            {userRole !== Roles.VIEWER && (
              <MenuItem icon={<IconTrash />} active={pathname === "/trash-cards"}>
                Trash Cards
                <Link to="/trash-cards" />
              </MenuItem>
            )}
            {userRole === Roles.ADMIN && (
              <MenuItem icon={<IconUsers />} active={pathname === "/users"}>
                Users <Link to="/users" />
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}

export default memo(Sidebar);
