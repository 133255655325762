/* eslint-disable jsx-a11y/alt-text */
import { useMantineTheme } from "@mantine/core";

export const FilePreview = (props: any) => {
  const theme = useMantineTheme();
  const { uploadedFileName, uploadedFileUrl } = props;

  return (
    <div className="dropzone-inner-container">
      <div className="d-flex">
        <a
          target="_blank"
          href={uploadedFileUrl}
          rel="noreferrer"
          style={{ color: theme.fn.primaryColor() }}
        >
          <img
            className="h-100 mx"
            src={props.path}
            width={35}
            height={35}
            id="fileIcon"
            title={uploadedFileName}
          />
        </a>
      </div>
    </div>
  );
};
