import "./Input.css";

function Input(props: any) {
  return (
    <input
      className="input-style w-100"
      type="text"
      {...props}
      value={props.value}
      onChange={props.onInputChange}
    />
  );
}
export default Input;
