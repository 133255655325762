import axios, { AxiosResponse } from "axios";

const axiosInstance = axios.create();
const CancelToken = axios.CancelToken;
let cancel_req: GlobalObjectInterface;

export { cancel_req };

interface GlobalObjectInterface {
  [key: string]: any;
}

export interface AxiosParams {
  method: string;
  url: string;
  data?: GlobalObjectInterface;
  isAccessTokenRequire?: boolean;
  contentType?: string;
  Authorization?: string;
}

export interface MiscellaneousRequestParams {
  onUploadProgress?: any;
  isAccessTokenRequire?: boolean;
  contentType?: string;
  Authorization?: string;
}

/**
 * post method
 * @param request object containing axios params
 */
const post = (
  url: string,
  params: GlobalObjectInterface = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "POST",
    url,
    data: params,
    ...otherData,
  });
};

/**
 * get method
 * @param request object containing axios params
 */
const get = (url: string, otherData: MiscellaneousRequestParams = {}) => {
  return commonAxios({ method: "GET", url, ...otherData });
};

/**
 * deleteRequest method
 * @param request object containing axios params
 */
const deleteRequest = (
  url: string,
  params: GlobalObjectInterface = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "DELETE",
    url,
    data: params,
    ...otherData,
  });
};

/**
 * put method
 * @param request object containing axios params
 */
const put = (
  url: string,
  params: GlobalObjectInterface = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PUT",
    url,
    data: params,
    ...otherData,
  });
};

/**
 * commonAxios
 * @param object containing method, url, data, access token, content-type
 */
const commonAxios = ({
  method,
  url,
  data,
  isAccessTokenRequire = true,
  contentType = "application/json",
  Authorization = "",
  ...otherData
}: AxiosParams): Promise<GlobalObjectInterface> => {
  const headers: GlobalObjectInterface = {
    // "Content-Type": contentType,
  };
  const token =
    isAccessTokenRequire &&
    "Bearer" +
      (Authorization ||
        "MTA5ZjkwMmYtNGZkMS00ODgxLWFmNjgtOWI1M2I5NjZmN2E3fDkzMjAyMTM4LTk1ZjQtNDNkZi04ZmJhLWE2NGY2ZjQ2ZDZhMA==");
  if (token) {
    headers["Authorization"] = `${token}`;
  }
  let body: string | GlobalObjectInterface | null = null;
  body = data as GlobalObjectInterface;
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: method,
      url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/${url}`,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_req = c;
      }),
      headers: headers,
      data: body,
      ...otherData,
    })
      .then((response: AxiosResponse<GlobalObjectInterface>) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpService = {
  post,
  get,
  put,
  deleteRequest,
};

export { axiosInstance };

export default httpService;
