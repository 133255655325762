import { useEffect, useState } from "react";
import "./Login.css";
import Input from "../common/Input/Input";
import Button from "../common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../common/Toast/Toast";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../firebase";
import httpService from "../../services/http.service";
import { setFirebaseUser, setUser } from "../../reducers/userReducer";
import { useDispatch } from "react-redux";
import { messageConstants } from "../../constant/message.constant";
import { LoadingOverlay, useMantineTheme } from "@mantine/core";
import { setErrorStatusCode } from "../../reducers/authReducer";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async () => {
    if (!email) {
      setError(messageConstants.EMAIL_MISSING);
      return;
    }
    if (!password) {
      setError(messageConstants.PASSWORD_MISSING);
      return;
    }
    try {
      setIsLoading(true);
      const res: any = await signInWithEmailAndPassword(auth, email, password);
      dispatch(setFirebaseUser(res.user));
      const idToken = await auth?.currentUser?.getIdToken(true);
      const userData = await httpService.post("auth/login", { idToken }, {});
      setError("");
      dispatch(setUser(userData.data));
      if (
        userData.data.is2FAviaEmailAvailable &&
        userData.data.is2FAviaPhoneNoAvailable
      ) {
        navigate("/choose-2fa-type");
      } else {
        const idToken = await auth?.currentUser?.getIdToken(true);
        await httpService.post(
          "auth/reset-email-verification",
          { idToken },
          {}
        );
        await sendEmailVerification(res.user);
        navigate("/verify-email");
      }
    } catch (error: any) {
      setIsLoading(false);
      console.log("ERROR: ", error);
      setError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    dispatch(setErrorStatusCode(null))
  }, [])

  return (
    <div className="login-container">
      {error && <Toast message={error} />}
      <LoadingOverlay visible={isLoading} loaderProps={{ variant: "oval" }} />
      {!isLoading && (
        <div className="child-container w-100 m-2">
          <div className="text-center mb-6">
            <img src="../../logo/maaglim.webp"></img>
          </div>
          <div className="input-group">
            <div className="my-1">Email</div>
            <Input
              type="text"
              placeholder="Enter email"
              value={email}
              onInputChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <div className="my-1">Password</div>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onInputChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <div className="login-btn">
            <Button text="Login" handleClick={login} />
          </div>
          <div className="mt-4 text-center">
            <span className="color-light-grey">Don't have an account?</span>{" "}
            <Link style={{ color: theme.fn.primaryColor() }} to="/signup">
              Sign up
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
