import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  ActionIcon,
  Box,
  Checkbox,
  Flex,
  LoadingOverlay,
  MediaQuery,
  Pagination,
  Paper,
  Select,
  Table,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconEdit,
  IconFilterOff,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import CustomModal from "../common/modal";
import { DateRangePicker } from "@mantine/dates";
import AddEditCardForm from "../addEditCardForm";
import { FilePreview } from "../common/FilePreview";
import httpService from "../../services/http.service";
import { MultiSelect } from "react-multi-select-component";
import { TableRecordsLimit, fileExtensionMap } from "../../constant/constant";
import {
  extractImgName,
  getFileExtension,
  getFormattedDate,
  getOriginalFileName,
} from "../utils";
import { useDebouncedState, useDebouncedValue } from "@mantine/hooks";
import Toast from "../common/Toast/Toast";
import { setErrorStatusCode, setUserRole } from "../../reducers/authReducer";

function Home() {
  const permissions = useSelector((state: any) => state.auth.userPermissions);
  const [userPermissions, setUserPermissions] = useState(permissions);
  const dispatch = useDispatch();

  const accessToken = useSelector((state: any) => state.auth.accessToken);

  const [data, setData] = useState<any[]>([]);
  const [meta, setMeta] = useState<Record<string, string>>({});
  const [activePage, setPage] = useState(1);
  const [recordsLimit, setRecordsLimit] = useState<string>(TableRecordsLimit);
  const [cardsToDelete, setCardsToDelete] = useState<string[]>([]);

  const [error, setError] = useState("");
  const [selectedCard, setSelectedCard] = useState<any | undefined>();
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionType, setActionType] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const [filterFields, setFilterFields] = useState<any>({});
  const emptySelectedFilters = {
    serialNumbers: [],
    manufactureNames: [],
    packageTypes: [],
    componentTypes: [],
    updatedAt: [null, null],
  };
  const [selectedFilters, setSelectedFilters] = useDebouncedState<any>(
    emptySelectedFilters,
    300
  );

  const getQueryStringValues = (key: string) => {
    return selectedFilters[key].map((obj: any) => obj.value).join(",");
  };

  const getPermissions = useCallback(() => {
    if (accessToken) {
      httpService
        .get("permission/get-user-permissions", {
          Authorization: accessToken,
        })
        .then((res) => {
          setIsLoading(false);
          dispatch(setUserRole(res.data.role));
          setUserPermissions(res.data.permissions);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
    }
  }, []);

  const fetchData = useCallback(() => {
    if (accessToken) {
      let queryParams = `card?limit=${recordsLimit}&page=${activePage}`;
      if (debouncedSearchQuery)
        queryParams += `&manufactureName=${debouncedSearchQuery}&packageType=${debouncedSearchQuery}`;
      if (selectedFilters.serialNumbers.length) {
        queryParams += `&serialNumbers=${getQueryStringValues(
          "serialNumbers"
        )}`;
      }
      if (selectedFilters.manufactureNames.length) {
        queryParams += `&manufactureNames=${getQueryStringValues(
          "manufactureNames"
        )}`;
      }
      if (selectedFilters.packageTypes.length) {
        queryParams += `&packageTypes=${getQueryStringValues("packageTypes")}`;
      }
      if (selectedFilters.componentTypes.length) {
        queryParams += `&componentTypes=${getQueryStringValues(
          "componentTypes"
        )}`;
      }
      if (selectedFilters.updatedAt.length) {
        const [start, end] = selectedFilters.updatedAt;
        if (start && end) {
          const formattedStart = dayjs(start).format("YYYY-MM-DD");
          const formattedEnd = dayjs(end).format("YYYY-MM-DD");
          queryParams += `&updatedAt=${formattedStart},${formattedEnd}`;
        }
      }
      httpService
        .get(queryParams, {
          Authorization: accessToken,
        })
        .then((res) => {
          setIsLoading(false);
          const cards = res.data.data.map((card: any) => ({
            ...card,
            checked: false,
          }));
          setData(cards);
          setMeta(res.data.meta);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
      fetchFilterFields();
    } else {
      setIsLoading(false);
    }
  }, [recordsLimit, activePage, debouncedSearchQuery, selectedFilters]);

  const fetchFilterFields = useCallback(() => {
    if (!data) return;
    httpService
      .get("card/filter-fields", { Authorization: accessToken })
      .then((response) => {
        Object.keys(response.data).map((key) => {
          response.data[key] = response.data[key].map((f: any) => ({
            label: f[key],
            value: f[key],
          }));
        });
        setFilterFields(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedCard(undefined);
      setSelectedCardId(undefined);
      setActionType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleEdit = (selectedCard: any) => {
    let {
      id,
      serial_no,
      manufacture_name,
      nominal_dimensions,
      package_type,
      component_type,
      file,
      fullFilePath,
      stp_file,
      stpFullFilePath,
      step_file,
      stepFullFilePath,
      dxf_file,
      dxfFullFilePath,
      dwg_file,
      dwgFullFilePath,
      dataset_file,
      datasetFullFilePath,
    } = selectedCard;
    
    setSelectedCard({
      id,
      serial_no,
      manufacture_name,
      nominal_dimensions,
      package_type,
      component_type,
      uploadedDraFileName: file,
      uploadedDraFileUrl: fullFilePath,
      uploadedStpFileName: stp_file,
      uploadedStpFileUrl: stpFullFilePath,
      uploadedStepFileName: step_file,
      uploadedStepFileUrl: stepFullFilePath,
      uploadedDxfFileName: dxf_file,
      uploadedDxfFileUrl: dxfFullFilePath,
      uploadedDwgFileName: dwg_file,
      uploadedDwgFileUrl: dwgFullFilePath,
      uploadedDatasetFileName: dataset_file,
      uploadedDatasetFileUrl: datasetFullFilePath,
    });

    setSelectedCardId(id);
    setActionType("editCard");
    toggleModal();
  };

  const handleDelete = (cardId: string) => {
    setSelectedCardId(cardId);
    setActionType("deleteCard");
    toggleModal();
  };

  const handleSubmit = async (cardData: any) => {
    const { values, files, removedFiles } = cardData as {
      values: any;
      files: any;
      removedFiles: { [key: string]: string };
    };

    try {
      let resultData: any;
      /* if (
        JSON.stringify(values) !== JSON.stringify(selectedCard) ||
        Object.keys(removedFiles).length > 0
      ) { */
        setActionLoading(true);
        const formData = new FormData();
        if (files) {
          for (const fileType in files) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            files[fileType] && files[fileType].length
              ? formData.append(fileType, files[fileType][0])
              : null;
          }
        }

        formData.append("manufactureName", values.manufacture_name);
        formData.append("packageType", values.package_type);
        formData.append("componentType", values.component_type);
        formData.append("nominalDimensions", values.nominal_dimensions);

        if (actionType === "editCard") {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          Object.keys(removedFiles).length > 0
            ? formData.append("removedFiles", JSON.stringify(removedFiles))
            : null;
          const res = await httpService.put(
            `card/${selectedCardId}`,
            formData,
            { Authorization: accessToken }
          );
          resultData = res.data;
        } else {
          formData.append("serialNo", values.serial_no);
          const res = await httpService.post("card", formData, {
            Authorization: accessToken,
          });
          resultData = res.data;
        }
      // }
      /* if (files) {
        let hasFile = false;
        const formData = new FormData();
        for (const fileType in files) {
          hasFile = hasFile || files[fileType] !== undefined;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          files[fileType] && files[fileType].length
            ? formData.append(fileType, files[fileType][0])
            : null;
        }
        if (hasFile) {
          const res = await httpService.put(
            `card/upload/${resultData ? resultData?.id : values?.id}`,
            formData,
            {
              Authorization: accessToken,
            }
          );
          resultData = res.data;
        }
      } */
      if (resultData) fetchData();
      setActionLoading(false);
      setTimeout(() => {
        toggleModal();
      }, 100);
    } catch (error: any) {
      setActionLoading(false);
      setError(error?.response?.data?.message || error?.message);
      dispatch(setErrorStatusCode(error?.response?.status));
    }
  };
  const renderFile = (file: any = "", filePath: any = "") => {
    return file
      .split(",")
      .map((file: string, index: number) => (
        <FilePreview
          key={index}
          uploadedFileName={file}
          uploadedFileUrl={filePath[index]}
          path={fileExtensionMap[getFileExtension(file).replace(".", "")]}
        />
      ));
  };
  const deleteCard = () => {
    setActionLoading(true);
    httpService
      .deleteRequest(
        `card/${selectedCardId}/false`,
        {},
        { Authorization: accessToken }
      )
      .then(() => {
        if (activePage === 1) {
          fetchData();
        } else {
          setPage(1);
        }
        toggleModal();
        setActionLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  };

  const deleteBulkCard = () => {
    setActionLoading(true);
    httpService
      .deleteRequest(
        "card/bulk",
        {
          ids: cardsToDelete,
          isDeleteFromTrash: false,
        },
        {
          Authorization: accessToken,
        }
      )
      .then(() => {
        if (activePage === 1) {
          fetchData();
        } else {
          setPage(1);
        }
        setIsLoading(false);
        toggleModal();
        setActionLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  };

  const handleDeleteBulkCard = () => {
    const cards = data.filter((c) => c.checked).map((c) => c.id);
    if (!cards.length) return;
    setCardsToDelete(cards);
    setActionType("deleteBulkCard");
    toggleModal();
  };

  const toggleCardCheck = (checked: boolean, cardId: string) => {
    const updatedCards = data.map((c) => {
      if (c.id === cardId) c.checked = checked;
      return c;
    });
    setData(updatedCards);
  };

  const rows = data.map((element) => (
    <tr key={element.id}>
      {userPermissions?.includes("card.delete") && (
        <td>
          <Checkbox
            checked={element.checked}
            onChange={(e) => toggleCardCheck(e.target.checked, element.id)}
            size="xs"
          />
        </td>
      )}
      <td>{element.manufacture_name || "NA"}</td>
      <td>{element.serial_no}</td>
      <td>
        {element.dataset_file ? (
          <FilePreview
            uploadedFileName={getOriginalFileName(element.dataset_file)}
            uploadedFileUrl={element.datasetFullFilePath}
            path={"/icons/pdf.png"}
          />
        ) : (
          "-"
        )}
      </td>

      <td>
        {element.file ? (
          <FilePreview
            uploadedFileName={element.file}
            uploadedFileUrl={element.fullFilePath}
            path={"/icons/footprint.png"}
          />
        ) : (
          "-"
        )}
      </td>
      <td
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {element.stp_file ? (
          <FilePreview
            uploadedFileName={getOriginalFileName(element.stp_file)}
            uploadedFileUrl={element.stpFullFilePath}
            path={"/icons/3d_model.png"}
          />
        ) : (
          ""
        )}
        {element.step_file ? (
          <FilePreview
            uploadedFileName={getOriginalFileName(element.step_file)}
            uploadedFileUrl={element.stepFullFilePath}
            path={"/icons/3d_model.png"}
          />
        ) : (
          ""
        )}
        {element.dxf_file ? (
          <FilePreview
            uploadedFileName={getOriginalFileName(element.dxf_file)}
            uploadedFileUrl={element.dxfFullFilePath}
            path={"/icons/DXF.jpg"}
          />
        ) : (
          ""
        )}
        {element.dwg_file ? (
          <FilePreview
            uploadedFileName={getOriginalFileName(element.dwg_file)}
            uploadedFileUrl={element.dwgFullFilePath}
            path={"/icons/DWG.png"}
          />
        ) : (
          ""
        )}
      </td>
      <td>{element.component_type || "NA"}</td>
      <td>{element.package_type || "NA"}</td>
      <td>{element.nominal_dimensions || "NA"}</td>
      <td>{getFormattedDate(element.updated_at)}</td>
      <td>
        <div className="d-flex">
          {userPermissions?.includes("card.update") && (
            <div className="mx-1">
              <ActionIcon
                size="sm"
                color="maaglim-blue"
                variant="subtle"
                onClick={() => handleEdit(element)}
              >
                <IconEdit></IconEdit>
              </ActionIcon>
            </div>
          )}
          {userPermissions?.includes("card.delete") && (
            <div className="mx-1">
              <ActionIcon
                size="sm"
                color="maaglim-red.9"
                variant="subtle"
                onClick={() => handleDelete(element.id)}
              >
                <IconTrash></IconTrash>
              </ActionIcon>
            </div>
          )}
        </div>
      </td>
    </tr>
  ));

  const handleRecordsLimitChange = (limit: string) => {
    setRecordsLimit(limit);
    setPage(1);
  };

  const handleSelectedFiltersChange = (key: string, value: any) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
  };

  const renderFilters = () => {
    return (
      <div className="d-flex align-items-center gap-1 filter-fields">
        {error && <Toast message={error} />}
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Manufacturer Name</label>
          <MultiSelect
            options={filterFields?.manufacture_name || []}
            value={selectedFilters?.manufactureNames || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("manufactureNames", list)
            }
            labelledBy="Filter by Manufacturer Name"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Manufacturer Part Number</label>
          <MultiSelect
            options={filterFields?.serial_no || []}
            value={selectedFilters?.serialNumbers || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("serialNumbers", list)
            }
            labelledBy="Filter by Manufacture Part Number"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Component Type</label>
          <MultiSelect
            options={filterFields.component_type || []}
            value={selectedFilters?.componentTypes || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("componentTypes", list)
            }
            labelledBy="Filter by Component Type"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Mounting Style</label>
          <MultiSelect
            options={filterFields?.package_type || []}
            value={selectedFilters?.packageTypes || []}
            onChange={(list: any) =>
              handleSelectedFiltersChange("packageTypes", list)
            }
            labelledBy="Filter by Mounting Style"
          />
        </div>
        <div className="d-flex flex-col flex-grow">
          <label className="filter-field-label">Date Modified</label>
          <DateRangePicker
            placeholder="Pick dates"
            onChange={(dates) =>
              handleSelectedFiltersChange("updatedAt", dates)
            }
            withinPortal
          />
        </div>
        <Tooltip label="Reset Filters" withinPortal>
          <ActionIcon
            color="maaglim-blue"
            variant="filled"
            onClick={() => setSelectedFilters(emptySelectedFilters)}
          >
            <IconFilterOff />
          </ActionIcon>
        </Tooltip>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Box p={"md"}>
          {actionType !== "deleteBulkCard" ? (
            <CustomModal
              open={isModalOpen}
              title={`${selectedCardId ? "Edit" : "Add"} card`}
              closeModal={toggleModal}
              confirmationString={
                actionType === "deleteCard"
                  ? "Are you sure you want to delete this card?"
                  : undefined
              }
              isOk={deleteCard}
              loading={actionLoading}
              styles={{ modal: { width: "100%", maxWidth: "770px" } }}
            >
              {["addCard", "editCard"].includes(actionType) && (
                <AddEditCardForm
                  packageTypes={filterFields?.package_type || []}
                  componentTypes={filterFields?.component_type || []}
                  onSubmit={handleSubmit}
                  actionType={actionType}
                  initialValues={selectedCard}
                  actionLoading={actionLoading}
                />
              )}
            </CustomModal>
          ) : (
            <CustomModal
              open={isModalOpen}
              title="Delete Cards"
              closeModal={toggleModal}
              confirmationString="Are you sure you want to delete selected cards?"
              isOk={deleteBulkCard}
              loading={actionLoading}
              children={false}
            />
          )}
          <Paper shadow="xs" p="sm" withBorder mb={"md"}>
            <MediaQuery
              query="(max-width: 575px)"
              styles={{
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <div className="d-flex justify-space-between">
                <div>
                  <Title order={3}>DRA Card Management</Title>
                </div>
                <div className="d-flex justify-space-between gap-1">
                  {/* commented for the time being */}
                  {/*  <TextInput
                    placeholder="Search"
                    className="w-100"
                    value={searchQuery}
                    onChange={(event) =>
                      setSearchQuery(event.currentTarget.value)
                    }
                  /> */}
                  {userPermissions?.includes("card.create") && (
                    <div className="d-flex gap-1">
                      <Tooltip label="Add Card">
                        <ActionIcon
                          size="lg"
                          color="maaglim-blue"
                          variant="filled"
                          onClick={() => {
                            toggleModal();
                            setActionType("addCard");
                          }}
                        >
                          <IconPlus />
                        </ActionIcon>
                      </Tooltip>
                      {userPermissions?.includes("card.delete") && (
                        <Tooltip label="Delete Cards">
                          <ActionIcon
                            size="lg"
                            color="maaglim-red.9"
                            variant="filled"
                            onClick={() => {
                              handleDeleteBulkCard();
                            }}
                          >
                            <IconTrash />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </MediaQuery>
          </Paper>
          <MediaQuery query="(max-width: 767px)" styles={{ display: "none" }}>
            <Paper shadow="xs" p="sm" withBorder mb={"md"}>
              {renderFilters()}
            </Paper>
          </MediaQuery>
          <MediaQuery query="(max-width: 767px)" styles={{ display: "block" }}>
            <Paper shadow="xs" withBorder mb={"md"} sx={{ display: "none" }}>
              <Accordion variant="filled">
                <Accordion.Item value="filters">
                  <Accordion.Control>Filters</Accordion.Control>
                  <Accordion.Panel>{renderFilters()}</Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Paper>
          </MediaQuery>
          <Paper
            shadow="xs"
            p="lg"
            withBorder
            mb={"md"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
              marginBottom: "0 !important",
            }}
          >
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ variant: "oval" }}
            />
            {!isLoading && (
              <>
                {rows?.length ? (
                  <>
                    <div className="table-scrollbar">
                      <Table
                        className="custom-table"
                        striped
                        verticalSpacing={"sm"}
                        horizontalSpacing={"sm"}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Manufacture Name</th>
                            <th>Manufacture Part Number</th>
                            <th>Datasheet</th>
                            <th>Footprint</th>
                            <th>CAD Files</th>
                            <th>Component type</th>
                            <th>Mounting Style</th>
                            <th>Nominal Dimensions</th>
                            <th>Date Modified</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </Table>
                    </div>
                    <Flex
                      justify="space-between"
                      direction="row"
                      sx={{ marginTop: "0.5rem" }}
                    >
                      <Pagination
                        page={activePage}
                        onChange={(page) => setPage(page)}
                        total={parseInt(meta.totalPages)}
                      />
                      <Select
                        value={recordsLimit}
                        data={[
                          { value: "5", label: "5" },
                          { value: "10", label: "10" },
                          { value: "15", label: "15" },
                          { value: "20", label: "20" },
                        ]}
                        size="xs"
                        sx={{ maxWidth: "75px" }}
                        onChange={(e) => e && handleRecordsLimitChange(e)}
                      />
                    </Flex>
                  </>
                ) : (
                  <div className="text-center font-bold font-size-28">
                    No card found
                  </div>
                )}
              </>
            )}
          </Paper>
        </Box>
      </div>
    </React.Fragment>
  );
}
export default Home;
