import { Tuple } from "@mantine/core";

export const maaglimBlueColor: Tuple<string, 10> = [
  "#e6eef6",
  "#ccdded",
  "#b3cce4",
  "#99bbdb",
  "#80aad2",
  "#6798c8",
  "#4d87bf",
  "#3476b6",
  "#1a65ad",
  "#0154a4",
];

export const maaglimRedColor: Tuple<string, 10> = [
  "#fae9e9",
  "#f5d2d4",
  "#f0bcbe",
  "#eba6a8",
  "#e79093",
  "#e2797d",
  "#dd6367",
  "#d84d51",
  "#d3363c",
  "#ce2026",
];
