import "./ConfirmationDialog.css";
import Button from "../Button/Button";

function ConfirmationDialog(props: any) {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={props.OnCancel}>
          &times;
        </span>
        <p className="color-light-grey">{props?.text}</p>
        <div className="d-flex justify-flex-end">
          <div className="mr-2">
            <Button text={props.confirmText} handleClick={props.onConfirm} />
          </div>
          <div>
            <Button text={props.cancelText} handleClick={props.OnCancel} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConfirmationDialog;
