import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toast({ message, success }: any) {
  const showMessage = () => {
    if (success) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    showMessage();
  }, [message]);

  return <ToastContainer />;
}
export default Toast;
