export const TwoFactorType = {
  EMAIL: "email",
  PHONE_NO: "phone-no",
};

export const TableRecordsLimit = "10";

export const acceptableCardFileExt = ["dra", "dxf", "step", "stp", "dwg"];
export const acceptablePdfFileExt = ["pdf"];
export const fileExtensionMap: any = {
  dra: "/icons/footprint.png",
  dxf: "/icons/DXF.jpg  ",
  step: "/icons/3d_model.png",
  stp: "/icons/3d_model.png",
  dwg: "/icons/DWG.png",
  pdf: "/icons/pdf.png",
};
export const extensionToFieldMap: any = {
  dra: "dra",
  dxf: "dxf",
  step: "step",
  stp: "stp",
  dwg: "dwg",
  pdf: "dataset",
};
export const Roles = {
  ADMIN: "admin",
  VIEWER: "viewer",
  EDITOR: "editor",
};
