import { Box, Button, Group, Modal, Text, Title } from "@mantine/core";
import { ReactNode } from "react";

interface ModalProps {
  open: boolean;
  title: string | ReactNode;
  closeModal: () => void;
  children: ReactNode;

  centered?: boolean;
  withCloseButton?: boolean;
  loading?: boolean;
  className?: string;
  styles?: any;

  // Must need to pass "isOk" props if you passing "confirmationString" props, else "Yes" button will not be appear.
  confirmationString?: string;
  isOk?: () => void;
}

const CustomModal = (props: ModalProps) => {
  const {
    open,
    title,
    withCloseButton,
    centered,
    children,
    className,
    closeModal,
    confirmationString,
    loading,
    isOk,
    styles,
  } = props;
  return (
    <Modal
      styles={styles}
      opened={open}
      withCloseButton={withCloseButton || true}
      className={className}
      transition={`slide-${open ? "down" : "up"}`}
      onClose={closeModal}
      title={
        confirmationString ? (
          <Title order={3}>Confirmation!</Title>
        ) : typeof title === "string" ? (
          <Title order={3}>{title || "Title"}</Title>
        ) : (
          title
        )
      }
      centered={centered || false}
    >
      {/* Modal content */}
      {confirmationString && (
        <Box>
          <Text>{confirmationString}</Text>
          <Group position="right" mt="md">
            <Button
              type="button"
              color="gray"
              onClick={closeModal}
              disabled={loading}
            >
              No
            </Button>
            {isOk && (
              <Button type="button" onClick={isOk} loading={loading}>
                Yes
              </Button>
            )}
          </Group>
        </Box>
      )}
      {!confirmationString && children}
    </Modal>
  );
};

export default CustomModal;
