import { auth } from "../../firebase";
import {
  setAccessToken,
  setPhoneNoConfirmationResult,
  setRefreshToken,
  setUserPermission,
  setUserRole,
} from "../../reducers/authReducer";
import { setFirebaseUser, setUser } from "../../reducers/userReducer";

export const getFormattedDate = (date: string) =>
  new Date(date).toLocaleDateString("en-GB", {
    weekday: "long",

    year: "numeric",
    month: "long",
    day: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",

    hour12: true,
  });

export const SignOut = async (dispatch: any, navigate: any) => {
  try {
    await auth?.signOut();
    dispatch(setUser(null));
    dispatch(setFirebaseUser(null));
    dispatch(setAccessToken(""));
    dispatch(setRefreshToken(""));
    dispatch(setPhoneNoConfirmationResult(null));
    dispatch(setUserRole(""));
    dispatch(setUserPermission(null));
    navigate("/login");
  } catch (error: any) {
    console.log("ERROR: ", error);
  }
};

export const getFileExtension = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf("."));
};

export const getOriginalFileName = (fileName: string) => {
  const splitFileName = fileName.split("**");
  splitFileName.pop();
  return splitFileName.join("").concat(getFileExtension(fileName));
}