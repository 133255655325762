import "./DropZone.css";
import { useEffect, useState } from "react";
import { Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { FilePreview } from "../FilePreview";
import Toast from "../Toast/Toast";
import { messageConstants } from "../../../constant/message.constant";
import {
  extensionToFieldMap,
  fileExtensionMap,
} from "../../../constant/constant";
import { getFileExtension } from "../../utils";

function DropZone(props: any) {
  const { uploadedFileUrl, uploadedFileName } = props;
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [fileUrl, setFileUrl] = useState<any>([]);
  const [filePath, setFilePath] = useState<any>([]);

  useEffect(() => {
    if (file?.length) {
      const filePathArr = props.isSingleFile
        ? [{ filePath: file[0].name }]
        : file.map((fileVal: any) => ({ filePath: fileVal.name }));

      setFilePath(filePathArr);

      const fileUrlArr = props.isSingleFile
        ? [URL.createObjectURL(file[0])]
        : file.map((fileVal: any) => URL.createObjectURL(fileVal));

      setFileUrl(fileUrlArr);
    }
    if (file?.length > 0) {
      props.setFile(file);
    }
  }, [file]);
  useEffect(() => {
    if (uploadedFileUrl) {
      setFileUrl(uploadedFileUrl);
    }
  }, [uploadedFileUrl]);

  useEffect(() => {
    const uploadedFileNameArr =
      typeof uploadedFileName === "string"
        ? [{ filePath: uploadedFileName }]
        : uploadedFileName
            ?.split(",")
            .map((fileVal: any) => ({ filePath: fileVal }));

    if (uploadedFileNameArr) setFilePath(uploadedFileNameArr);
  }, [uploadedFileName]);

  const clearFile = (e: any) => {
    setFile(null);
    setFileUrl("");
    const title = e.target.getAttribute("title");
    props.clear(
      extensionToFieldMap[
        getFileExtension(title).replace(".", "").toLowerCase()
      ],
      title
    );
  };

  const cardFileValidator = (file: File | DataTransferItem) => {
    if (error) setError("");
    if (!(file instanceof File))
      return {
        message: messageConstants.FILE_NOT_FOUND,
        code: messageConstants.FILE_INVALID_CODE,
      };
    const extension = file.name.split(".").pop();
    if (
      extension &&
      props.acceptFile.includes(extension ? extension.toLowerCase() : "")
    )
      return null;
    const message = messageConstants.FILE_NOT_ACCEPTABLE;
    setError(message);
    return {
      message,
      code: messageConstants.FILE_INVALID_CODE,
    };
  };

  return (
    <div className="dropzone-container">
      {error && <Toast message={error} />}
      {fileUrl.length === 0 ? (
        <Dropzone
          validator={cardFileValidator}
          onDrop={setFile}
          multiple={!props.isSingleFile}
          name={props.name}
          styles={{ root: { height: "100px", width: "200px", padding: "2px" } }}
        >
          <div className="dropzone-inner-container">
            <div className="d-flex">
              <Dropzone.Idle>
                <img
                  className="h-100 mr-2"
                  src="../../logo/file-icon.png"
                  alt=""
                  width={20}
                  height={20}
                />
              </Dropzone.Idle>
              <div>
                <Text size="sm" inline>
                  Drag files here or click to select file
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Attach a file
                </Text>
              </div>
            </div>
          </div>
        </Dropzone>
      ) : (
        <div className="dropzone-image-container flex flex-wrap">
          <div onClick={clearFile}>
            <img
              src="../../logo/close.png"
              className="close-style"
              alt="close"
              title={uploadedFileName}
            />
          </div>
          {filePath.map((fileVal: any) => {
            const extension: string = fileVal?.filePath?.split(".")[1];

            return (
              <FilePreview
                path={
                  fileExtensionMap[extension ? extension.toLowerCase() : ""]
                }
                uploadedFileName={fileVal.filePath}
                uploadedFileUrl={fileVal.fileUrl}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
export default DropZone;
