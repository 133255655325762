import {
  ActionIcon,
  Box,
  Checkbox,
  Flex,
  LoadingOverlay,
  Pagination,
  Paper,
  Select,
  Table,
  Title,
  Tooltip,
} from "@mantine/core";
import Toast from "../common/Toast/Toast";
import Input from "../common/Input/Input";
import CustomModal from "../common/modal";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../utils";
import Button from "../common/Button/Button";
import httpService from "../../services/http.service";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { TableRecordsLimit } from "../../constant/constant";
import React, { useState, useCallback, useEffect } from "react";
import { setErrorStatusCode, setUserRole } from "../../reducers/authReducer";

function Users() {
  const accessToken = useSelector((state: any) => state.auth.accessToken);
  const dispatch = useDispatch();

  const [data, setData] = useState<any[]>([]);
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [meta, setMeta] = useState<Record<string, string>>({});
  const [activePage, setPage] = useState(1);
  const [recordsLimit, setRecordsLimit] = useState<string>(TableRecordsLimit);
  const [usersToDelete, setUsersToDelete] = useState<string[]>([]);

  const [error, setError] = useState("");
  const [selectedUser, setSelectedUser] = useState<any | undefined>();
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionType, setActionType] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = useCallback(() => {
    if (accessToken) {
      const queryParams = `user?limit=${recordsLimit}&page=${activePage}`;
      httpService
        .get(queryParams, {
          Authorization: accessToken,
        })
        .then((res) => {
          setIsLoading(false);
          setData(res.data.data);
          setMeta(res.data.meta);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
    } else {
      setIsLoading(false);
    }
  }, [recordsLimit, activePage]);

  const fetchRoleData = useCallback(() => {
    if (accessToken) {
      httpService
        .get("role", {
          Authorization: accessToken,
        })
        .then((res) => {
          const arr: any = [];
          res.data?.forEach((data: any) => {
            arr.push({ value: data?.id, label: data?.role });
          });
          setRolesData([...arr]);
        })
        .catch((error) => {
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
    } else {
      setIsLoading(false);
    }
  }, [recordsLimit, activePage]);

  const getPermissions = useCallback(() => {
    if (accessToken) {
      httpService
        .get("permission/get-user-permissions", {
          Authorization: accessToken,
        })
        .then((res) => {
          setIsLoading(false);
          dispatch(setUserRole(res.data.role));
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error?.response?.data?.message || error?.message);
          dispatch(setErrorStatusCode(error?.response?.status));
        });
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  useEffect(() => {
    fetchRoleData();
  }, []);

  const toggleModal = () => {
    if (isModalOpen) {
      setActionType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleEdit = (selectedUser: any) => {
    setSelectedUser(selectedUser);
    setSelectedUserId(selectedUser?.id);
    setActionType("editUser");
    toggleModal();
  };

  const handleEditRole = () => {
    httpService
      .put(
        "user/role",
        {
          userId: selectedUserId,
          roleId: selectedUser.role_id,
        },
        { Authorization: accessToken }
      )
      .then(() => {
        fetchData();
        toggleModal();
      })
      .catch((error) => {
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  };

  const handleDelete = (userId: string) => {
    setSelectedUserId(userId);
    setActionType("deleteUser");
    toggleModal();
  };

  const deleteUser = () => {
    setActionLoading(true);
    httpService
      .deleteRequest(
        `user/${selectedUserId}`,
        {},
        { Authorization: accessToken }
      )
      .then(() => {
        fetchData();
        setActionLoading(false);
        setTimeout(() => {
          toggleModal();
        }, 100);
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
  };

  const deleteBulkUser = () => {
    setActionLoading(true);
    httpService
      .deleteRequest(
        "user/bulk",
        { ids: usersToDelete },
        {
          Authorization: accessToken,
        }
      )
      .then(() => {
        setIsLoading(false);
        fetchData();
      })
      .catch((error) => {
        setIsLoading(false);
        setActionLoading(false);
        setError(error?.response?.data?.message || error?.message);
        dispatch(setErrorStatusCode(error?.response?.status));
      });
    toggleModal();
    setActionLoading(false);
  };

  const handleDeleteUserBulk = () => {
    const users = data.filter((c) => c.checked).map((c) => c.id);
    if (!users.length) return;
    setUsersToDelete(users);
    setActionType("deleteBulkUser");
    toggleModal();
  };

  const toggleUserCheck = (checked: boolean, userId: string) => {
    const updatedUsers = data.map((c) => {
      if (c.id === userId) c.checked = checked;
      return c;
    });
    setData(updatedUsers);
  };

  const rows = data?.map((element) => (
    <tr key={element.id}>
      <td>
        <Checkbox
          checked={element.checked}
          onChange={(e) => toggleUserCheck(e.target.checked, element.id)}
          size="xs"
        />
      </td>
      <td>{element.email_id}</td>
      <td>{element.profile_image_url || "-"}</td>
      <td>{element.is_email_verified?.toString()}</td>
      <td>{element.is_phone_verified?.toString()}</td>
      <td>{element.phone_no || "-"}</td>
      <td>{element.role || "-"}</td>
      <td>{getFormattedDate(element.created_at)}</td>
      <td>
        <div className="d-flex">
          <div className="mx-1">
            <ActionIcon
              size="sm"
              color="maaglim-blue"
              variant="subtle"
              onClick={() => {
                handleEdit(element);
              }}
            >
              <IconEdit />
            </ActionIcon>
          </div>

          <div className="mx-1">
            <ActionIcon
              size="sm"
              color="maaglim-red.9"
              variant="subtle"
              onClick={() => handleDelete(element.id)}
            >
              <IconTrash />
            </ActionIcon>
          </div>
        </div>
      </td>
    </tr>
  ));

  const handleRecordsLimitChange = (limit: string) => {
    setRecordsLimit(limit);
    setPage(1);
  };

  return (
    <>
      {error && <Toast message={error} />}
      <Box p={"md"}>
        {actionType !== "deleteBulkUser" ? (
          <CustomModal
            open={isModalOpen}
            title={"Edit user"}
            closeModal={toggleModal}
            confirmationString={
              actionType === "deleteUser"
                ? "Are you sure you want to delete this user?"
                : undefined
            }
            isOk={deleteUser}
            loading={actionLoading}
          >
            {actionType === "editUser" && (
              <React.Fragment>
                <div className="input-group">
                  <div className="my-1">Email</div>
                  <Input
                    type="text"
                    placeholder="Enter email"
                    value={selectedUser?.email_id}
                    disabled
                  />
                </div>
                <div className="input-group">
                  <div className="my-1">Role</div>
                  <Select
                    value={selectedUser?.role_id}
                    data={[...rolesData]}
                    onChange={(e) =>
                      e && setSelectedUser({ ...selectedUser, role_id: e })
                    }
                  />
                  <div className="mt-4">
                    <Button text="Submit" handleClick={handleEditRole} />
                  </div>
                </div>
              </React.Fragment>
            )}
          </CustomModal>
        ) : (
          <CustomModal
            open={isModalOpen}
            title="Delete Users"
            closeModal={toggleModal}
            confirmationString="Are you sure you want to delete selected users?"
            isOk={deleteBulkUser}
            loading={actionLoading}
            children={false}
          />
        )}
        <Paper shadow="xs" p="sm" withBorder mb={"md"}>
          <div className="d-flex justify-space-between">
            <div>
              <Title order={3}>User List</Title>
            </div>
            <Tooltip label="Delete Users">
              <ActionIcon
                size="lg"
                color="maaglim-red.9"
                variant="filled"
                onClick={() => {
                  handleDeleteUserBulk();
                }}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </div>
        </Paper>
        <Paper
          shadow="xs"
          p="lg"
          withBorder
          mb={"md"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
            marginBottom: "0 !important",
          }}
        >
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ variant: "oval" }}
          />
          {!isLoading && (
            <>
              {rows?.length ? (
                <>
                  <div className="table-scrollbar">
                    <Table
                      className="custom-table"
                      striped
                      verticalSpacing={"sm"}
                      horizontalSpacing={"sm"}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>Email</th>
                          <th>Profile</th>
                          <th>Email verified</th>
                          <th>Phone verified</th>
                          <th>Phone no</th>
                          <th>Role</th>
                          <th>Created At</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </Table>
                  </div>
                  <Flex
                    justify="space-between"
                    direction="row"
                    sx={{ marginTop: "0.5rem" }}
                  >
                    <Pagination
                      page={activePage}
                      onChange={(page) => setPage(page)}
                      total={parseInt(meta.totalPages)}
                    />
                    <Select
                      value={recordsLimit}
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                      size="xs"
                      sx={{ maxWidth: "75px" }}
                      onChange={(e) => e && handleRecordsLimitChange(e)}
                    />
                  </Flex>
                </>
              ) : (
                <div className="text-center font-bold font-size-28">
                  No user found
                </div>
              )}
            </>
          )}
        </Paper>
      </Box>
    </>
  );
}
export default Users;
