import "./ChooseTwoFaOption.css";
import { useState } from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../firebase";
import Button from "../common/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../common/Toast/Toast";
import { TwoFactorType } from "../../constant/constant";
import httpService from "../../services/http.service";
import { setUser } from "../../reducers/userReducer";
import { setErrorStatusCode, setPhoneNoConfirmationResult } from "../../reducers/authReducer";
import { LoadingOverlay, Radio } from "@mantine/core";

function ChooseTwoFaOption() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTwoFactorType, setSelectedTwoFactorType] = useState(
    TwoFactorType.EMAIL
  );
  const navigate = useNavigate();
  const userData = useSelector((state: any) => state.user.userData);
  const firebaseUser = useSelector((state: any) => state.user.firebaseUser);

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      if (selectedTwoFactorType === TwoFactorType.EMAIL) {
        const idToken = await auth?.currentUser?.getIdToken(true);
        await httpService.post(
          "auth/reset-email-verification",
          { idToken },
          {}
        );
        if (firebaseUser) {
          await sendEmailVerification(firebaseUser);
          const data = {
            ...userData,
            is2FAviaPhoneNoAvailable: false,
          };
          dispatch(setUser(data));
        }
        navigate("/verify-email");
      } else {
        const captchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          auth
        );
        captchaVerifier.render();

        const response = await signInWithPhoneNumber(
          auth,
          userData.phone_no,
          captchaVerifier
        );
        dispatch(setPhoneNoConfirmationResult(response));
        const data = {
          ...userData,
          is2FAviaEmailAvailable: false,
        };
        dispatch(setUser(data));
        navigate("/verify-phone-no");
      }
    } catch (error: any) {
      setIsLoading(false);
      console.log("ERROR: ", error);
      setError(error?.response?.data?.message || error?.message);
      dispatch(setErrorStatusCode(error?.response?.status))
    }
  };

  return (
    <div className="verify-container">
      <div id="recaptcha-container"></div>
      {error && <Toast message={error} />}
      <LoadingOverlay visible={isLoading} loaderProps={{ variant: "oval" }} />
      {!isLoading && (
        <div className="child-container w-100 m-2 text-center">
          <div className="text-center mb-6">
            <img src="../../logo/maaglim.webp"></img>
          </div>
          <div className="my-1 text-center font-bold">
            Select an option for 2FA verification
          </div>
          <div className="d-flex justify-space-around mt-3 mb-4">
            <Radio.Group
              value={selectedTwoFactorType}
              onChange={setSelectedTwoFactorType}
              name="favoriteFramework"
              className="verify-options-label"
            >
              <Radio value={TwoFactorType.EMAIL} label="Email" />
              <Radio value={TwoFactorType.PHONE_NO} label="Phone no" />
            </Radio.Group>
          </div>
          <div className="mt-4 confirm-btn">
            <Button text="Confirm" handleClick={onConfirm} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChooseTwoFaOption;
